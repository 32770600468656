import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { yupResolver } from '@hookform/resolvers/yup';
import { AddCircle, Close, Edit } from '@mui/icons-material';
import { Button, Chip, ListItem, Stack, Typography } from '@mui/material';
import { getCreateSchoolMutationOptions, getUpdateSchoolMutationOptions, isLoading, onValidationError, } from 'api';
import { ConfirmationDialog, LoadingWrapper, Select, TextFormItem, } from 'components/custom';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDialogMode } from 'utils/dialog-form';
import { onSubmitError, yup } from 'utils/forms';
import { useCreateSchoolMutation, useUpdateSchoolMutation, } from 'api/generated';
import { mebibytes2Bytes } from 'utils/math';
const MAX_NUMBER_OF_SEATS = 1000;
const MAX_STORAGE_PER_USER = 100;
const SchoolManagementEditDialog = (props) => {
    var _a;
    const { schoolToEdit, close } = props;
    const id = React.useId();
    const { t } = useTranslation('schoolManagement', {
        keyPrefix: 'SchoolManagementEditDialog',
    });
    const [domainInputValue, setDomainInputValue] = React.useState('');
    const [, forceRender] = React.useState({});
    const [createSchool, statusCreateSchool] = useCreateSchoolMutation();
    const [updateSchool, statusUpdateSchool] = useUpdateSchoolMutation();
    const { modeCreate, open } = useDialogMode(schoolToEdit);
    const mutationStatus = modeCreate ? statusCreateSchool : statusUpdateSchool;
    const schema = React.useMemo(() => yup.object({
        domains: yup
            .array()
            .label(t('domainsField.label'))
            .default([])
            .min(1)
            .of(yup.string().required()),
        name: yup.string().label(t('nameField.label')).required(),
        seats: yup
            .number()
            .label(t('seatsField.label'))
            .required()
            .integer()
            .min(1)
            .max(MAX_NUMBER_OF_SEATS),
        status: yup
            .string()
            .label(t('statusField.label'))
            .required(),
        storageSizeGB: yup
            .number()
            .label(t('storageSizeGBField.label'))
            .required()
            .integer()
            .min(0)
            .max(MAX_STORAGE_PER_USER),
    }), [t]);
    const { handleSubmit, control, formState: { errors, isSubmitting }, reset, getValues, setValue, setError, clearErrors, } = useForm({
        resolver: yupResolver(schema),
    });
    React.useEffect(() => {
        setDomainInputValue('');
        reset(schoolToEdit !== null && schoolToEdit !== void 0 ? schoolToEdit : {});
    }, [schoolToEdit, reset]);
    const handleSuccess = () => {
        close();
        enqueueSnackbar(t('successMessage'));
    };
    const onSubmit = async (values) => {
        var _a;
        const input = {
            domains: (_a = values.domains) !== null && _a !== void 0 ? _a : [],
            name: values.name,
            seats: values.seats,
            status: values.status,
            storageSizeBytes: mebibytes2Bytes(values.storageSizeGB),
        };
        const mutation = (schoolToEdit === null || schoolToEdit === void 0 ? void 0 : schoolToEdit.id)
            ? updateSchool(getUpdateSchoolMutationOptions({ id: schoolToEdit.id, ...input }))
            : createSchool(getCreateSchoolMutationOptions(input));
        return mutation.then(handleSuccess).catch(onValidationError);
    };
    const addDomain = () => {
        var _a;
        if (!domainInputValue) {
            return;
        }
        const oldDomains = (_a = getValues('domains')) !== null && _a !== void 0 ? _a : [];
        if (oldDomains.includes(domainInputValue)) {
            setError('domains', {
                message: t('domainsField.domainAlreadyAddedError'),
            });
            return;
        }
        if (domainInputValue.includes('@')) {
            setError('domains', {
                message: t('domainsField.atSignForbiddenError'),
            });
            return;
        }
        setValue('domains', [...oldDomains, domainInputValue]);
        setDomainInputValue('');
    };
    return (_jsx(ConfirmationDialog, { open: open, onClose: close, onTransitionExited: () => {
            mutationStatus.reset();
        }, titleProps: {
            Icon: modeCreate ? AddCircle : Edit,
            text: modeCreate ? t('addSchoolTitle') : t('editSchoolTitle'),
        }, cancelButtonProps: { onClick: close }, confirmButtonProps: {
            disabled: isSubmitting || isLoading([statusCreateSchool, statusUpdateSchool]),
            form: id,
            type: 'submit',
        }, fullWidth: true, children: _jsx(LoadingWrapper, { mutation: mutationStatus, children: _jsx("form", { id: id, onSubmit: (e) => {
                    handleSubmit(onSubmit)(e).catch(onSubmitError);
                }, children: _jsxs(Stack, { spacing: 2, children: [_jsx(Controller, { control: control, name: "name", render: ({ field }) => {
                                var _a;
                                return (_jsx(TextFormItem, { ...field, label: t('nameField.label'), placeholder: t('nameField.placeholder'), errorMessage: (_a = errors.name) === null || _a === void 0 ? void 0 : _a.message }));
                            } }), _jsxs(Stack, { direction: "row", spacing: 1, children: [_jsx(Controller, { control: control, name: "seats", render: ({ field }) => {
                                        var _a;
                                        return (_jsx(TextFormItem, { ...field, label: t('seatsField.label'), placeholder: t('seatsField.placeholder'), errorMessage: (_a = errors.seats) === null || _a === void 0 ? void 0 : _a.message }));
                                    } }), _jsx(Controller, { control: control, name: "storageSizeGB", defaultValue: 10, render: ({ field }) => {
                                        var _a;
                                        return (_jsx(TextFormItem, { ...field, label: t('storageSizeGBField.label'), placeholder: t('storageSizeGBField.placeholder'), errorMessage: (_a = errors.storageSizeGB) === null || _a === void 0 ? void 0 : _a.message }));
                                    } }), _jsx(Controller, { control: control, name: "status", defaultValue: "ACTIVE", render: ({ field }) => {
                                        var _a;
                                        return (_jsx(Select, { ...field, label: t('statusField.label'), options: [
                                                {
                                                    label: t('statusField.options.ACTIVE'),
                                                    value: 'ACTIVE',
                                                },
                                                {
                                                    label: t('statusField.options.INACTIVE'),
                                                    value: 'INACTIVE',
                                                },
                                            ], placeholder: t('statusField.placeholder'), errorMessage: (_a = errors.status) === null || _a === void 0 ? void 0 : _a.message }));
                                    } })] }), _jsxs(Stack, { spacing: 0, children: [_jsx(Controller, { control: control, name: "domains", render: ({ field }) => {
                                        var _a;
                                        return (_jsx(TextFormItem, { ...field, label: t('domainsField.label'), placeholder: t('domainsField.placeholder'), value: domainInputValue, onChange: (event) => {
                                                setDomainInputValue(event.currentTarget.value.trim());
                                                clearErrors('domains');
                                            }, onKeyDown: (e) => {
                                                if (e.key === 'Enter') {
                                                    e.preventDefault();
                                                    addDomain();
                                                }
                                            }, errorMessage: (_a = errors.domains) === null || _a === void 0 ? void 0 : _a.message, InputProps: {
                                                endAdornment: (_jsx(Button, { onClick: addDomain, color: "secondary", variant: "contained", children: _jsx(Typography, { whiteSpace: "nowrap", children: t('domainsField.button') }) })),
                                            }, sx: { '& .MuiInputBase-root': { pr: 0.5 } } }));
                                    } }), _jsx(Stack, { direction: "row", role: "list", children: ((_a = getValues('domains')) !== null && _a !== void 0 ? _a : []).map((domain) => (_jsx(ListItem, { sx: { width: 'auto' }, children: _jsx(Chip, { color: "warning", clickable: false, label: domain, onDelete: () => {
                                                var _a;
                                                setValue('domains', ((_a = getValues('domains')) !== null && _a !== void 0 ? _a : []).filter((d) => d !== domain));
                                                forceRender({});
                                            }, deleteIcon: _jsx(Close, {}) }) }, domain))) })] })] }) }) }) }));
};
export default SchoolManagementEditDialog;
