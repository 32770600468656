import { SchoolsDocument, useSchoolsQuery } from 'api/generated';
export const useSchoolsQueryApi = () => {
    var _a;
    const { data, ...rest } = useSchoolsQuery();
    const schools = ((_a = data === null || data === void 0 ? void 0 : data.schools) !== null && _a !== void 0 ? _a : [])
        .slice()
        .sort((a, b) => a.name.localeCompare(b.name));
    return { data: { __typename: 'Query', schools }, ...rest };
};
export const getCreateSchoolMutationOptions = (school) => ({
    refetchQueries: [SchoolsDocument],
    variables: {
        input: school,
    },
});
export const getUpdateSchoolMutationOptions = (school) => ({
    variables: {
        input: school,
    },
});
export const getDeleteSchoolMutationOptions = (id) => ({
    refetchQueries: [SchoolsDocument],
    variables: {
        id,
    },
});
