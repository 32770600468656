import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import { setTitle } from 'utils/head';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { Button, DataGrid, LoadingWrapper } from 'components/custom';
import { AddCircle, Delete, Edit } from '@mui/icons-material';
import { useSchoolsQueryApi } from 'api/school-management';
import SchoolManagementEditDialog from './SchoolManagementEditDialog';
import { bytes2Mebibytes } from 'utils/math';
import SchoolManagementDeleteDialog from './SchoolManagementDeleteDialog';
const SchoolManagement = () => {
    var _a, _b;
    const { t } = useTranslation('schoolManagement');
    const schoolsStatus = useSchoolsQueryApi();
    const [schoolToDelete, setSchoolToDelete] = React.useState(null);
    const [schoolToEdit, setSchoolToEdit] = React.useState(null);
    const columns = [
        {
            field: 'name',
            headerName: t('columns.name'),
            minWidth: 200,
            sortable: false,
        },
        {
            field: 'status',
            headerName: t('columns.status'),
            renderCell: (params) => t(`SchoolManagementEditDialog.statusField.options.${params.row.status}`),
            sortable: false,
        },
        { field: 'seats', headerName: t('columns.seats'), sortable: false },
        {
            field: 'storageSizeGB',
            headerName: t('columns.storageSize'),
            renderCell: (params) => bytes2Mebibytes(params.row.storageSizeBytes),
            sortable: false,
            width: 160,
        },
        {
            field: 'domains',
            flex: 1,
            headerName: t('columns.domains'),
            renderCell: (params) => params.row.domains.join(', '),
            sortable: false,
        },
        {
            field: 'actions',
            headerName: '',
            renderCell: (params) => (_jsxs(Box, { children: [_jsx(IconButton, { color: "secondary", onClick: () => {
                            setSchoolToEdit(params.row);
                        }, title: t('editSchoolButton'), children: _jsx(Edit, {}) }), _jsx(IconButton, { color: "error", onClick: () => {
                            setSchoolToDelete(params.row);
                        }, title: t('deleteSchoolButton'), children: _jsx(Delete, {}) })] })),
            sortable: false,
        },
    ];
    useEffect(() => {
        setTitle(t('title'));
    }, [t]);
    return (_jsxs(Stack, { spacing: 2, children: [_jsx(Typography, { color: "secondary", fontWeight: "bold", variant: "h6", children: t('title') }), _jsxs(LoadingWrapper, { status: schoolsStatus, children: [_jsx(DataGrid, { autoHeight: true, hideFooter: true, columns: columns, rows: (_b = (_a = schoolsStatus.data) === null || _a === void 0 ? void 0 : _a.schools) !== null && _b !== void 0 ? _b : [], sx: {
                            '& .MuiDataGrid-row:nth-of-type(odd)': {
                                '&:hover': {
                                    backgroundColor: (theme) => theme.palette.action.hover,
                                },
                                backgroundColor: (theme) => theme.palette.inactive.main,
                            },
                            border: 'none',
                            fontSize: '0.9rem',
                        } }), _jsx(Button, { color: "secondary", onClick: () => {
                            setSchoolToEdit({});
                        }, startIcon: _jsx(AddCircle, {}), sx: { alignSelf: 'end' }, children: t('addSchoolButton') }), _jsx(SchoolManagementEditDialog, { close: () => {
                            setSchoolToEdit(null);
                        }, schoolToEdit: schoolToEdit }), _jsx(SchoolManagementDeleteDialog, { close: () => {
                            setSchoolToDelete(null);
                        }, schoolToDelete: schoolToDelete })] })] }));
};
export default SchoolManagement;
